<template>
	<p
		data-component="records-count"
		v-if="hasPagination"
	>
		{{ getCountString }}
	</p>
</template>

<script>
	export default {
		props: {
			pagination: {
				type: Object,
				default: () => {}
			},
			total: {
				type: Number,
				default: 0
			}
		},
		computed: {
			getCountString () {
				if (this.total <= 0) {
					return 'No results found';
				}
				const itemRange = `${this.getLowerLimit}-${this.getUpperLimit}`;
				const items = `${itemRange} of ${this.total}`;
				const totalPages  = `${this.counts.pages} ${this.getPageString}`;
				return `Showing ${items} in ${totalPages}.`;
			},
			hasPagination () {
				return this.pagination !== undefined;
			},
			counts () {
				return {
					per:      this.pagination.perPage,
					pages: 		this.pagination.totalPages,
					current: 	this.pagination.currentPage
				};
			},
			getPageString () {
				return this.counts.pages > 1 ? 'pages' : 'page';
			},
			getLowerLimit () {
				const perPage = this.total / this.counts.pages;
				if (this.counts.current === 1) {
					return 1;
				}
				return parseInt(perPage * this.counts.current) - this.counts.per;
			},
			getUpperLimit () {
				if (this.counts.current === this.counts.pages) {
					return this.total;
				}
				return this.getLowerLimit + this.counts.per - 1;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='records-count'] {
		width:100%;
		font-size:rem(18);
		color:$c-brand-grey;
		text-align:center;
	}

</style>
