<template>
	<div data-component="filter-options">
		<h1 data-element="filter-heading">
			{{ filter.text }}
		</h1>
		<select-field
			:name="filter.text"
			:options="filter.options"
			:hasLabel="false"
			v-model="getValue"
			multiple
		/>
	</div>
</template>

<script>

	import SelectField from '@/components/forms/SelectField';

	export default {
		components: {
			SelectField
		},
		props: {
			filter: {
				type: Object,
				required: true
			},
			value: {
				type: Array,
				default: () => ([])
			}
		},
		data: () => ({}),
		computed: {
			getValue: {
				get () {
					return this.value;
				},
				set (newVal) {
					this.$emit('setFilterValue', {
						filter: this.filter.value,
						value: newVal
					});
				}
			}
		},
		methods: {
			getFilterOptionIsActiveByOption (option) {
				return this.value.includes(option.value);
			},
			removeFilterOption (option) {
				this.$emit('setFilterValue', {
					filter: this.filter.value,
					value: this.value.filter((thisOption) => {
						return thisOption !== option.value;
					})
				});
			},
			addFilterOption (option) {
				this.$emit('setFilterValue', {
					filter: this.filter.value,
					value: [
						...this.value,
						option.value
					]
				});
			},
			toggleFilterOption (option) {
				if (this.getFilterOptionIsActiveByOption(option)) {
					this.removeFilterOption(option);
					return;
				}
				this.addFilterOption(option);
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='filter-options'] {
		margin-top:rem(28);
		[data-element='filter-heading'] {
			margin:0 0 rem(14);
			font-size:rem(16);
			font-weight:700;
			color:$c-brand-blue;
		}
	}
</style>
