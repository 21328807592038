<template>
	<form
		data-component="filters"
		v-if="filters"
	>
		<active-filters
			:filters="filters"
			:activeFilters="activeFilters"
			@changeFilters="changeFilters"
			@removeFilter="removeFilter"
		/>
		<portal
			to="modal"
			v-if="showModal"
		>
			<filter-modal
				:filters="filters"
				:activeFilters="activeFilters"
				@setFilterValue="setFilterValue"
				@clearAllFilterOptions="clearAllFilterOptions"
				@closeModal="closeModal"
			/>
		</portal>
	</form>
</template>

<script>

	import FilterModal    from '@/components/ui/views/filters/FilterModal';
	import ActiveFilters  from '@/components/ui/views/filters/ActiveFilters';

	export default {
		components: {
			FilterModal,
			ActiveFilters
		},
		props: {
			filters: {
				type: Array,
				default: undefined
			}
		},
		data: () => ({
			activeFilters:  {},
			showModal:      false
		}),
		computed: {
			getDefaultFilterOptionsFromQuery () {
				const query = this.$route.query;
				const validQueryFilters = Object.keys(query).filter((queryKey) => {
					return this.filters.map((filter) => {
						return filter.value;
					}).includes(queryKey);
				}).map((queryKey) => {
					return {
						[queryKey]: [
							query[queryKey]
						]
					};
				});
				return Object.assign({}, ...validQueryFilters);
			},
			getDefaultFilterOptions () {
				const filtersWithDefaults = this.filters.filter((filter) => {
					return filter.default !== undefined;
				}).map((filter) => {
					return {
						[filter.value]: filter.default
					};
				});
				return Object.assign({}, ...filtersWithDefaults);
			}
		},
		created () {
			this.setInitialFilters();
		},
		methods: {
			setInitialFilters () {
				if (Object.keys(this.getDefaultFilterOptionsFromQuery).length) {
					this.setFilters(this.getDefaultFilterOptionsFromQuery);
				}
				if (Object.keys(this.getDefaultFilterOptions).length) {
					return this.setFilters(this.getDefaultFilterOptions);
				}
				this.setFilters();
			},
			setFilters (newFilters) {
				this.activeFilters = {
					...this.activeFilters,
					...newFilters
				};
				this.emitFilters();
			},
			removeFilter (filter) {
				const { [filter]: omitThisWithAHack, ...otherActiveFilters } = this.activeFilters;
				this.activeFilters = otherActiveFilters;
				return this.emitFilters();
			},
			setFilterValue ({ filter, value }) {
				if (Array.isArray(value) &&
						!value?.length) {
					return this.removeFilter(filter);
				}
				this.activeFilters = {
					...this.activeFilters,
					[filter]: value
				};
				this.emitFilters();
			},
			clearAllFilterOptions () {
				this.activeFilters = {};
				this.emitFilters();
			},
			changeFilters () {
				this.showModal = true;
			},
			closeModal () {
				this.showModal = false;
			},
			emitFilters () {
				this.$emit('filter', this.activeFilters);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='filters'] {
		width:100%;
	}

</style>
