<template>
	<form
		data-component="search-input"
		@submit.prevent="search"
	>
		<div data-element="input-wrapper">
			<icon
				data-element="search-icon"
				icon="search"
				colour="blue-lighter-2"
			/>
			<input-field
				:name="inputName"
				type="search"
				:showLabel="false"
				:placeholder="inputPlaceholder"
				v-model="searchString"
			/>
			<icon
				v-if="getSearchStringLength"
				data-element="clear-icon"
				icon="cross"
				@click.native="clearSearch"
				colour="blue-lighter-2"
			/>
		</div>
		<btn
			type="submit"
			text="Search"
		/>
	</form>
</template>

<script>

	import InputField  from '@/components/forms/InputField';
	import Icon        from '@/components/ui/Icon';
	import Btn         from '@/components/ui/Btn';

	export default {
		components: {
			InputField,
			Icon,
			Btn
		},
		props: {
			inputName: {
				type: String,
				default: 'Search'
			},
			inputPlaceholder: {
				type: String,
				default: undefined
			}
		},
		data: () => ({
			searchString: ''
		}),
		computed: {
			getSearchStringLength () {
				return this.searchString.length;
			}
		},
		methods: {
			search () {
				this.$emit('search', this.searchString);
			},
			clearSearch () {
				this.searchString = '';
				this.search();
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='search-input'] {
		position:relative;
		display:flex;
		align-items:center;
		width:100%;
		[data-element="input-wrapper"] {
			display:flex;
			flex-grow:1;
			margin-right:rem(12);
			background-color:$c-brand-grey-lighter-4;
			border-radius:4px;
			height:rem(40);
			[data-component='icon'] {
				width:rem(32);
				height:auto;
				text-selection:none;
			}
			[data-element='search-icon'] {
				margin-left:rem(6);
			}
			::v-deep [data-component='input-field'] {
				flex-grow:1;
				margin-left:rem(2);
				margin-right:rem(2);
				input {
					border:none;
					background-color:transparent;
					&,
					&::placeholder {
						color:$c-brand-blue-lighter-1;
					}
					&::placeholder {
						font-style:italic;
					}
					&::-webkit-search-decoration,
					&::-webkit-search-cancel-button,
					&::-webkit-search-results-button,
					&::-webkit-search-results-decoration {
						display: none;
					}
				}
			}
			[data-element='clear-icon'] {
				margin-right:rem(4);
				cursor:pointer;
			}
		}
		[data-element="cancel"] {
			font-size:rem(16);
			cursor:pointer;
			position:absolute;
			right:rem(10);
			outline:none;
			background: transparent;
			border:none;
			top:rem(4);
			padding:rem(2);
			box-sizing:border-box;
		}
	}

</style>
