<template>
	<div data-component="filter-item">
		<component
			:is="getComponent"
			:filter="filter"
			:value="value"
			@setFilterValue="setFilterValue"
		/>
	</div>
</template>

<script>

	import FilterOptions               from '@/components/ui/views/filters/FilterOptions';
	import FilterBoolean               from '@/components/ui/views/filters/FilterBoolean';
	import FilterExperienceLevelRange  from '@/components/ui/views/filters/FilterExperienceLevelRange';

	export default {
		components: {
			FilterOptions,
			FilterBoolean,
			FilterExperienceLevelRange
		},
		props: {
			filter: {
				type: Object,
				required: true
			},
			value: {
				type: [Array, Boolean, Object],
				default: undefined
			}
		},
		computed: {
			getComponent () {
				if (this.filter.type === 'boolean') {
					return 'FilterBoolean';
				}
				if (this.filter.type === 'experienceLevelRange') {
					return 'FilterExperienceLevelRange';
				}
				return 'FilterOptions';
			}
		},
		methods: {
			setFilterValue ({ filter, value }) {
				this.$emit('setFilterValue', {
					filter,
					value
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	[data-component='filter-item'] {
	}
</style>
