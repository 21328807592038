<template>
	<div
		data-component="badge"
		:data-has-icon="icon"
		:data-colour="colour"
	>
		<span
			data-element="text"
			@click="onClick"
		>
			{{ text }}
		</span>
		<icon
			v-if="icon"
			:icon="icon"
			colour="white"
			@click.native="onClickIcon"
		/>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			text: {
				type: String,
				default: ''
			},
			icon: {
				type: String,
				default: undefined
			},
			colour: {
				type: String,
				default: 'red'
			}
		},
		methods: {
			onClick () {
				this.$emit('click');
			},
			onClickIcon () {
				this.$emit('clickIcon');
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='badge'] {
		display:flex;
		align-items:center;
		height:rem(28);
		padding:rem(4) rem(10) rem(4) rem(10);
		border-radius:4px;
		&[data-colour='red'] {
			background-color:$c-brand-red;
		}
		&[data-colour='grey'] {
			background-color:$c-brand-grey;
		}
		&[data-colour='grey-lighter-1'] {
			background-color:$c-brand-grey-lighter-1;
		}
		&[data-colour='grey-lighter-2'] {
			background-color:$c-brand-grey-lighter-2;
		}
		&[data-has-icon] {
			padding-right:rem(4);
		}
		[data-element='text'] {
			font-size:rem(16);
			line-height:1;
			color:$c-white;
			cursor:pointer;
		}
		[data-component='icon'] {
			width:rem(24);
			height:auto;
			cursor:pointer;
		}
	}
</style>
