<template>
	<div data-component="filter-experience-level">
		<h1 data-element="filter-heading">
			{{ filter.text }}
		</h1>
		<div data-element="options">
			<div
				data-element="option"
				v-for="(option, key) in filter.options"
				:key="key"
				@click="clickFilterOption(option)"
				:data-active="getFilterOptionIsActiveByOption(option)"
				:data-level="option.value"
			>
				<div data-element="text">
					{{ option.value }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		components: {},
		props: {
			filter: {
				type: Object,
				required: true
			},
			value: {
				type: Array,
				default: () => ([])
			}
		},
		computed: {
			getNoValueIsSelected () {
				return !this.value?.length;
			},
			getSingleValueIsSelected () {
				return this.value?.length === 1;
			},
			getLowestActiveExperienceLevel () {
				if (!this.value ||
						!this.value?.length) {
					return false;
				}
				return Math.min(...this.value);
			},
			getHighestActiveExperienceLevel () {
				if (!this.value ||
						!this.value?.length) {
					return false;
				}
				return Math.max(...this.value);
			}
		},
		methods: {
			getFilterOptionIsActiveByOption (option) {
				if (!this.getLowestActiveExperienceLevel ||
						!this.getHighestActiveExperienceLevel) {
					return false;
				}
				if (option.value > this.getHighestActiveExperienceLevel) {
					return false;
				}
				if (option.value < this.getLowestActiveExperienceLevel) {
					return false;
				}
				return true;
			},
			clearExperienceLevels () {
				return this.$emit('setFilterValue', {
					filter: this.filter.value,
					value: []
				});
			},
			setFirstExperienceLevel (option) {
				return this.$emit('setFilterValue', {
					filter: this.filter.value,
					value: [
						option.value
					]
				});
			},
			getArrayOfLevelsBetweenNewValAndSingleVal (newVal) {
				const getNewValIsHigher = () => {
					return newVal > this.value[0];
				};
				const getDifference = () => {
					if (getNewValIsHigher()) {
						return newVal - this.value[0] + 1;
					}
					return this.value[0] - newVal + 1;
				};
				return Array.from({
					length: getDifference()
				}, (value, key) => {
					if (getNewValIsHigher()) {
						return key + this.value[0];
					}
					return key + newVal;
				});
			},
			setSecondExperienceLevel (option) {
				return this.$emit('setFilterValue', {
					filter: this.filter.value,
					value: this.getArrayOfLevelsBetweenNewValAndSingleVal(
						option.value
					)
				});
			},
			clickFilterOption (option) {
				if (this.getFilterOptionIsActiveByOption(option)) {
					return this.clearExperienceLevels();
				}
				if (this.getSingleValueIsSelected) {
					return this.setSecondExperienceLevel(option);
				}
				return this.setFirstExperienceLevel(option);
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='filter-experience-level'] {
		[data-element='filter-heading'] {
			margin:0 0 rem(14);
			font-size:rem(16);
			font-weight:700;
			color:$c-brand-blue;
		}
		[data-element='options'] {
			@include nested-grid;
			[data-element='option'] {
				position: relative;
				@include nested-cols(1, 8);
				@include nested-gut(8);
				border:3px solid $c-brand-blue;
				border-radius:4px;
				cursor:pointer;
				user-select:none;
				&:nth-of-type(8n) {
					margin-right:0;
				}
				&:before {
					display:block;
					content:'';
					width:100%;
					padding-top:math.div(5,4) * 100%;
				}
				[data-element='text'] {
					position:absolute;
					top:50%; left:50%;
					transform:translate3d(-50%,-50%,0);
					font-size:rem(44);
					font-weight:700;
					color:$c-blue;
				}
				[data-element='text'] {
					font-size:rem(12);
					@include mq('sm') {
						font-size:rem(24);
					}
					@include mq('md') {
						font-size:rem(32);
					}
					@include mq('lg') {
						font-size:rem(48);
					}
					@include mq('xl') {
						font-size:rem(64);
					}
				}
				&[data-level='2'],
				&[data-level='4'],
				&[data-level='6'],
				&[data-level='8'] {
					&:after {
						content:'';
						position:absolute;
						top:3px; right:3px;
						bottom:3px; left:3px;
						display:block;
						user-select:none;
					}
				}
				&[data-active] {
					[data-element='text'] {
						color:$c-white;
					}
					&[data-level='2'],
					&[data-level='4'],
					&[data-level='6'],
					&[data-level='8'] {
						&:after {
							content:'';
							position:absolute;
							top:3px; right:3px;
							bottom:3px; left:3px;
							display:block;
							border:2px solid $c-white;
							user-select:none;
						}
					}
				}
				&[data-level='2'] {
					&:after {
						border:2px solid $c-experience-levels-beginner-blue;
					}
				}
				&[data-level='4'] {
					&:after {
						border:2px solid $c-experience-levels-improver-green;
					}
				}
				&[data-level='6'] {
					&:after {
						border:2px solid $c-experience-levels-intermediate-red;
					}
				}
				&[data-level='8'] {
					&:after {
						border:2px solid $c-experience-levels-advanced-purple;
					}
				}
				&[data-level='1'],
				&[data-level='2'] {
					border-color:$c-experience-levels-beginner-blue;
					[data-element='text'] {
						color:$c-experience-levels-beginner-blue;
					}
					&[data-active] {
						background-color:$c-experience-levels-beginner-blue;
						[data-element='text'] {
							color:$c-white;
						}
					}
				}
				&[data-level='3'],
				&[data-level='4'] {
					border-color:$c-experience-levels-improver-green;
					[data-element='text'] {
						color:$c-experience-levels-improver-green;
					}
					&[data-active] {
						background-color:$c-experience-levels-improver-green;
						[data-element='text'] {
							color:$c-white;
						}
					}
				}
				&[data-level='5'],
				&[data-level='6'] {
					border-color:$c-experience-levels-intermediate-red;
					[data-element='text'] {
						color:$c-experience-levels-intermediate-red;
					}
					&[data-active] {
						background-color:$c-experience-levels-intermediate-red;
						[data-element='text'] {
							color:$c-white;
						}
					}
				}
				&[data-level='7'],
				&[data-level='8'] {
					border-color:$c-experience-levels-advanced-purple;
					[data-element='text'] {
						color:$c-experience-levels-advanced-purple;
					}
					&[data-active] {
						background-color:$c-experience-levels-advanced-purple;
						[data-element='text'] {
							color:$c-white;
						}
					}
				}
			}
		}
	}
</style>
