<template>
	<component
		data-component="table-view-row"
		:is="getComponent"
		:data-header="isHeader"
		v-bind="getProps"
		@click="click"
	>
		<span
			data-element="col"
			v-for="(column, key) in this.getColumns"
			:key="key"
			:data-label="getLabel(key)"
		>
			{{ column }}
		</span>
	</component>
</template>

<script>

	import action from '@/mixins/action';

	export default {
		mixins: [action],
		props: {
			isHeader: {
				type: Boolean,
				default: false
			},
			headings: {
				type: Array,
				default: () => []
			},
			columns: {
				type: Array,
				default: () => []
			}
		},
		computed: {
			getColumns () {
				if (this.isHeader) {
					return this.headings;
				}
				return this.columns;
			}
		},
		methods: {
			getLabel (index) {
				if (!this.headings?.[index]) {
					return false;
				}
				return this.headings[index];
			}
		}
	};

</script>

<style lang="scss" scoped>
	.shrink {
		flex-grow: 0;
	}

	[data-component='table-view-row'] {
		display:flex;
		flex-direction:column;
		width:100%;
		border-bottom: 1px solid $c-brand-grey-lighter-2;
		padding:rem(12) 0;
		@include mq('sm') {
			flex-direction:row;
			padding:0;
		}
		&[data-header] {
			display:none;
			@include mq('sm') {
				display:flex;
			}
			[data-element='col'] {
				font-weight:700;
			}
		}
		[data-element='col'] {
			display:flex;
			margin:rem(6) 0;
			&:before {
				content:attr(data-label);
				margin:auto auto auto 0;
				font-weight:700;
			}
			@include mq('sm') {
				overflow:hidden;
				align-items:center;
				justify-content:flex-start;
				flex-basis:0;
				flex-grow:1;
				max-width:100%;
				min-height:rem(40);
				padding:rem(12) 0;
				font-size:rem(18);
				color:$c-text;
				&:before {
					content:none;
					display:none;
				}
			}
		}
	}

</style>
