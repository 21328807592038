var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-component":"select-field"}},[_c('validation-provider',{attrs:{"tag":"div","rules":_vm.rules,"vid":_vm.getVid,"name":_vm.getName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
return [_c('div',{attrs:{"data-error":errors && errors[0]}},[(_vm.hasLabel)?_c('label',{attrs:{"data-element":"label","for":_vm.getId,"data-required":required},domProps:{"innerHTML":_vm._s(_vm.getLabel)}}):_vm._e(),_c('multiselect',{attrs:{"id":_vm.getId,"name":_vm.getName,"options":_vm.options,"showLabels":_vm.showLabels,"taggable":_vm.tags,"placeholder":_vm.getPlaceholder,"multiple":_vm.isMultiple,"allow-empty":_vm.emptyAllowed,"track-by":_vm.getValueAttr,"label":_vm.getLabelAttr,"searchable":_vm.searchable,"internal-search":_vm.internalSearch,"loading":_vm.searchIsLoading,"disabled":_vm.disabled,"selectLabel":_vm.selectLabel,"deselectLabel":_vm.deselectLabel,"hideSelected":_vm.hideSelected,"showNoOptions":_vm.showNoOptions},on:{"input":_vm.onChange,"tag":_vm.onTag,"search-change":_vm.onSearchChange},scopedSlots:_vm._u([(_vm.htmlOptions)?{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('span',{domProps:{"innerHTML":_vm._s(option[_vm.getLabelAttr])}})]}}:null,(_vm.htmlOptions)?{key:"option",fn:function(ref){
var option = ref.option;
return [_c('span',{domProps:{"innerHTML":_vm._s(option[_vm.getLabelAttr])}})]}}:null,(_vm.htmlOptions)?{key:"tag",fn:function(ref){
var option = ref.option;
var remove = ref.remove;
return [_c('span',{staticClass:"multiselect__tag"},[_c('span',{domProps:{"innerHTML":_vm._s(option[_vm.getLabelAttr])}}),_c('i',{staticClass:"multiselect__tag-icon",attrs:{"aria-hidden":"true","tabindex":"1"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return remove(option)},"mousedown":function($event){$event.preventDefault();return remove(option)}}})])]}}:null],null,true),model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})],1),(errors[0])?_c('p',{attrs:{"data-element":"error"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }