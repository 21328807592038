<template>
	<div data-component="modal-inner">
		<div
			data-element="modal-content"
			:data-size="size"
			:data-left-aligned="leftAligned"
		>
			<div data-element="modal-header">
				<h1
					v-if="heading"
					data-element="modal-heading"
				>
					{{ heading }}
				</h1>
				<icon
					data-element="close"
					icon="cross"
					colour="blue"
					@click.native="close"
				/>
			</div>
			<div data-element="modal-slot">
				<slot
					@close="close"
				/>
			</div>
		</div>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			heading: {
				type: String,
				default: undefined
			},
			size: {
				type: String,
				validator (value) {
					return [
						'sm',
						'md',
						'lg'
					].includes(value);
				},
				default: 'lg'
			},
			leftAligned: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			close () {
				this.$emit('close');
			},
			change () {
				this.$emit('change');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='modal-inner'] {
		@include grid;
		display:flex;
		flex-direction:column;
		margin:auto 0;
		[data-element='modal-content'] {
			@include cols(12);
			background:$c-white;
			border-radius:8px;
			box-shadow:0 1px 5px 0 rgba(0, 0, 0, 0.5);
			&[data-size='sm'] {
				@include mq('sm') {
					@include cols(6);
					@include push(3);
				}
			}
			&[data-size='md'] {
				@include mq('sm') {
					@include cols(8);
					@include push(2);
				}
			}
			&[data-size='lg'] {
				@include mq('sm') {
					@include cols(10);
					@include push(1);
				}
			}
			&[data-left-aligned] {
				@include mq('sm') {
					@include cols(8);
					@include push(0);
				}
			}
			[data-element='modal-header'] {
				display:flex;
				padding:rem(12) rem(20) rem(12) rem(24);
				border-bottom:1px solid $c-brand-grey-lighter-4;
				[data-element='modal-heading'] {
					margin:auto rem(22) auto 0;
					font-weight:700;
					font-size:rem(18);
					color:$c-brand-blue;
				}
				[data-element='close'] {
					margin:auto 0 auto auto;
					height:auto;
					cursor:pointer;
					user-select:none;
					height:rem(32);
					width:auto;
				}
			}
			[data-element='modal-slot'] {
				padding:rem(24) rem(24) rem(24);
			}
		}
	}

</style>
