<template>
	<div data-component="filter-boolean">
		<checkbox-field
			:name="filter.text"
			v-model="isChecked"
		/>
	</div>
</template>

<script>

	import CheckboxField from '@/components/forms/CheckboxField';

	export default {
		components: {
			CheckboxField
		},
		props: {
			filter: {
				type: Object,
				required: true
			},
			value: {
				type: Boolean,
				default: undefined
			}
		},
		computed: {
			isChecked: {
				get () {
					return this.value;
				},
				set (value) {
					this.$emit('setFilterValue', {
						filter: this.filter.value,
						value
					});
				}
			}
		},
		methods: {}
	};

</script>

<style lang="scss" scoped>
	[data-component='filter-boolean'] {
		height: 100%;
		display: flex;
		align-content: flex-end;
		flex-wrap: wrap;
		padding-bottom: rem(14);
	}
</style>
